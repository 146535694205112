<template>
  <div class="home">
    <HeaderNavigation :info="info"  :checkValue="10" />
    <SwiperBox class="animate__animated animate__bounceInUp" :imgList="imgList"></SwiperBox>


    <!-- <div class="title_box_txt">
      <div class="title1">产品展示</div>
      <div class="title2">Product display</div>
    </div>
    <div @click="productClick" class="link_txt">更多>></div> -->
    <div class="title-box">
        <div>
          <div class="title1">产品展示</div>
          <div class="title2">Product display</div>
        </div>
        <div @click="productClick" class="link_txt">更多>></div>
    </div>
    <div class="title-box" style="margin-bottom:30px">
        <div v-for="(t, idx) in types" :key="idx" class="type-box"  @mouseenter="changeType(t)" @click="productItemClick(t)">
          <img :src="t.image"  style="width:56px;height:56px;"/>
          <div>{{ t.type_name }}</div>
        </div>
    </div>
    <div class="one_box">
      <div class="prdocut_box" v-for="(item, index) of filterProduct" :key="index">
        <div class="item_one_kuai">
          <div class="item_one_kuai_shop_pic"><img :src="item.product_image" alt=""></div>
          <div class="bottom_txt">
            {{ item.product_name }}
          </div>
        </div>
      </div>
    </div>

    <div class="two_box">
      <div class="left_right_box">
            <div class="right_box">
              <div class="title1">{{us.title1}}</div>
              <div class="title2">Company Profile</div>     
              <div @click="detailBtn" class="details_btn">
                <div class="details_txt">查看详情</div>
                <img class="you_jian" src="../../assets/images/right.png" alt="" />
              </div>
            </div>
          <div class="content_txt" v-html="us.shouye_intro"></div>

        <!-- <div class="left_box">
          <img :src="us.company_image" alt="" />
        </div> -->
      </div>
    </div>
    
    <div  class="title-box">
        <div>
          <div class="title1">新闻中心</div>
          <div class="title2">News Center</div>
        </div>
        <div @click="newsListClick" class="link_txt">更多>></div>
    </div>
      <!-- <div class="title_box_txt">
        <div class="title1">新闻中心</div>
        <div class="title2">News Center</div>
      </div>

      <div @click="newsListClick" class="link_txt">更多链接>></div> -->

      <div class="nei_four_box">
        <div @click="new_detail(item)" v-for="(item,index) of news" :key="index" class="Journalismstyle">
          <div class="JournaPic"><img :src="item.news_image"></div>
          <div class="content_box">
            <div class="JournaTitle">{{item.news_title}}</div>
            <div class="JournaContent">{{item.news_introduction}}</div>
            <div class="btn_details">查看详情 ></div>
          </div>
        </div>
      </div>
    

    <div class="four_box">
      <div class="four_box_bg"></div>
      
    <div  class="title-box" style="padding-top:30px; margin-top:0">
        <div>
          <div class="title1">合作案例</div>
          <div class="title2">Collaboration case</div>
        </div>
        <div @click="pingpaiClick" class="link_txt">更多>></div>
    </div>
      <!-- <div class="title_box_txt">
        <div class="title1">品牌荣耀</div>
        <div class="title2">Brand glory</div>
      </div>
      <div @click="pingpaiClick" class="link_txt">更多>></div> -->
      <div :class="glory.length>4?'three_kuai after_box after_box1':'three_kuai'">
        <div  v-for="(item, index) of glory" :key="index">
          <div v-if="item.gloryImg" class="item_three_kuai">
            <div class="certificate"><img :src="item.gloryImg" alt=""></div>
            <div class="certificate_text">{{item.title}}</div>
          </div>
          <div v-else>
            <div style="width:200px"></div>

          </div>
        </div>
        
      </div>
    </div>



    <BottomNavigation :info="info" />
    <RightMenu  :info="info"  @openpop="openpop" />
    <JoinBulletFrame v-if="JoinModal" @closepop="closepop" />
    <Modal v-model="productModal" width="600">
        <p slot="header" style="">
            <span>产品图片</span>
        </p>
        <div style="text-align:center">
          <img style="width:100%" :src="productImg" alt="">
        </div>
        <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
// @ is an alias to /src
import {indexBanner,indexData,configshow} from '@/api/index.js'
import HeaderNavigation from "@/components/header_navigation.vue";
import BottomNavigation from "@/components/bottom_navigation.vue";
import RightMenu from "@/components/right-menu.vue";
import SwiperBox from "@/components/swiper.vue";
import JoinBulletFrame from "@/components/joinBulletFrame.vue";

export default {
  name: "Home",
  components: {
    HeaderNavigation,
    BottomNavigation,
    RightMenu,
    SwiperBox,
    JoinBulletFrame
  },

  data() {
    return {
      info:{},
      JoinModal:false,
      productModal:false,
      types:[],
      productImg:'',
      glory:[],
      news:[],
      active_type_id:0,
      product:[],
      us:{},
      imgList:[],
    };
  },
  computed: {
    filterProduct() {
      let product = this.product.filter(p =>
        p.type_id == this.active_type_id
      );
      return product.slice(0, 3);
    }
  },
  mounted() {
    let is_messgae = localStorage.getItem('message')
    console.log(is_messgae)
    if (!is_messgae) {
      this.JoinModal = true;
    }
    this.indexBanner()
    this.indexData()
    this.configshow()
  },
  methods:{
    changeType(t){
        this.active_type_id = t.id
    },
    configshow(){
      configshow().then(res=>{
        this.info = res.data
        this.$store.commit('setInfos',  this.info);
      })
    },
    indexBanner(){
      indexBanner().then(res=>{
        console.log(res.data)
        this.imgList = res.data
      })
    },
    productBtn(e){
      this.productImg = e.product_image
      this.productModal = true
    },
    detailBtn(){
      this.$router.push('/companyprofile');
    },
    indexData(){
      indexData().then(res=>{
        this.glory = res.data.glory
        this.news = res.data.news
        this.product = res.data.product
        this.types = res.data.type
        this.active_type_id = this.types[0].id
        this.us = res.data.us
      })
    },
    new_detail(e){
      // localStorage.setItem('Journalism',JSON.stringify(e))
      this.$router.push({path:"/newsinformation_detail",query:{id:e.id}})
    },
    productClick(){
      this.$router.push('/Productdetail')
    },
    productItemClick(e){
      this.$router.push({path:"/Productdetail",query:{id:e.id}})
    },
    pingpaiClick(){
      this.$router.push('/Glory')
    },
    newsListClick(){
      this.$router.push('/newsinformation')
    },
    openpop(e){
      this.JoinModal = e
    },
    closepop(e){
      this.JoinModal = e
    },
  }
};
</script>

<style scoped>
.title_box_txt {
  padding: 50px 0;
  text-align: center;
}
.title1 {
  font-size: 16px;
  font-weight: 600;
}
.title2 {
  color: #999;
  font-size: 12px;
  margin-top: 10px;
}
.one_box {
  width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.one_box::after {
  display: block;
  width: 382px;
  content: ' ';
}
/* .prdocut_box{
  width: 33.33%;
  display: flex;
  justify-content: center;
} */
.item_one_kuai {
      position: relative;
    width: 382px;
    height: 260px;
    margin-bottom: 20px;
    border-radius: 12px;
    overflow: hidden; 
}
.item_one_kuai_shop_pic{
  width: 100%;
  height: 200px;
  transition: 0.3s all;
}
.item_one_kuai_shop_pic:hover{
  width: 120%;
  transition: 0.3s all;
}
.item_one_kuai_shop_pic img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.three_kuai{
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.after_box::after{
  width: 260px;
  display: block;
  content: ' ';
}
.after_box1::after{
  width: 260px;
  display: block;
  content: ' ';
}
/* .three_kuai::after{
  width: 260px;
  display: block;
  content: ' ';
} */
.item_three_kuai {
  /* width: 25%; */
  margin-bottom: 30px;
}
.certificate{
  width: 260px;
  display: flex;
  background: #F9F9F9;
  padding: 30px 0;
  align-items: center;
  justify-content: center;
}
.certificate img{
  width: 200px;
  height: 160px;
  object-fit: cover;
}
.certificate_text{
  text-align: center;
  padding: 10px 0;
  font-size:16px;
  font-weight: bold;
}
.four_box{
  position: relative;
  width: 100%;
  padding: 0 0 60px 0;
  /* background: #F3F3F3; */
  background: url('../../assets/images/bg.png') no-repeat;
  background-size: 100%, 100%;
}
.nei_four_box{
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Journalismstyle{
  cursor: pointer;
  width: 300px;
  background: #FFF;
}
.JournaPic{
  width: 100%;
  height: 250px;
}
.JournaPic img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content_box{
  padding: 10px 20px;
}
.JournaTitle{
  overflow:hidden;
  text-overflow:ellipsis;
  -o-text-overflow:ellipsis;
  white-space:nowrap;
    font-size: 18px;
    font-weight: bold;
}
.JournaContent{
  /* height: 80px; */
  margin-top: 10px;
  margin-bottom: 20px;
  color: #999;
  font-size: 14px;
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box; 
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2; 

}
.btn_details{
  color: #313131;
  padding: 5px 0 20px 0;
  font-size: 14px;
}
.bottom_txt {
  width: 100%;
  height: 60px;
  background: #9a9a9a;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size:16px;
  font-weight: bold;
}

.two_box {
  width: 100%;
  background: #f3f3f3;
}
.left_right_box {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 100px 0;
}
.right_box {
  padding-right: 40px;
  width: 30%;
}
.left_box {
  width: 70%;
}
.left_box img {
  width: 100%;
}
.content_txt {
  font-size: 14px;
  margin-top: 20px;
}
.details_btn {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background: #313131;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 50px;
  padding: 10px 0;
}
.details_btn:hover .you_jian{
  right: 38px;
  transition: 0.3s all;
}
.details_btn:hover .details_txt{
  margin-right: 38px;
  transition: 0.3s all;
}
.details_txt {
  transition: 0.3s all;
}
.you_jian {
  position: absolute;
  top: 50%;
  transition: 0.3s all;
  right: -28px;
  margin-top: -14px;
  /* margin-left: 20px; */
  width: 28px;
}
.shop_da_box{
  width: 901px;
  margin: 0 auto;
  padding: 50px 0;
}
.shop_box{
  width: 100%;
  margin-top: 30px;
}
.nav_title_box{
  display: flex;
  align-items: center;
  width: 100%;
  overflow: auto;
}
.item_class_box{
  width: 100px;
  border-radius: 12px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
}
.yes_item_class_box{
  width: 100px;
  border-radius: 12px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  background: #A91800;
  color: #FFF;
}
.nav_content_box{
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.nav_content_box::after{
  width: 240px;
  height: 190px;
  display: block;
  content: ' ';
}
/* .item_content_box{
  width: 33.33%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
} */
.nei_item_content_box{
  width: 240px;
  margin-bottom: 20px;
  background: #F7F7F7;
}
.item_content_box_pic img{
  width: 100%;
  object-fit: cover;
}
.item_content_box_title{
  text-align: center;
  margin: 2px 0;
}
.link_txt{
  /* width: 80%; */
  cursor: pointer;
  /* margin: 0 auto 20px; */
  /* text-align: right; */
  color: #999;
}

.absol_icon{
  position: absolute;
  top: calc(100vh - 240px);
  left: 100px;
  width: 240px;
  height: 200px;
  /* background: #000; */
}
.absol_icon img{
  width: 100%;
  height: 100%;
}

.cake{animation:move 3s 0s infinite;
  -webkit-animation:move 3s 0s infinite;transform-origin:bottom;
  -webkit-transform-origin:bottom;
  cursor: pointer;
}

@keyframes move
{
	0%, 65%{ 
	  -webkit-transform:rotate(0deg);
	  transform:rotate(0deg);
	}
	70% {  
	  -webkit-transform:rotate(6deg);
	  transform:rotate(6deg);
	}
	75% {  
	  -webkit-transform:rotate(-6deg);
	  transform:rotate(-6deg);
	}
	80% {  
	  -webkit-transform:rotate(6deg);
	  transform:rotate(6deg);
	}
	85% {  
	  -webkit-transform:rotate(-6deg);
	  transform:rotate(-6deg);
	}
	90% {  
	  -webkit-transform:rotate(6deg);
	  transform:rotate(6deg);
	}
	95% {  
	  -webkit-transform:rotate(-6deg);
	  transform:rotate(-6deg);
	}
	100% {  
	  -webkit-transform:rotate(0deg);
	  transform:rotate(0deg);
	}
}

@-webkit-keyframes move
{
	0%, 65%{ 
	  -webkit-transform:rotate(0deg);
	  transform:rotate(0deg);
	}
	70% {  
	  -webkit-transform:rotate(6deg);
	  transform:rotate(6deg);
	}
	75% {  
	  -webkit-transform:rotate(-6deg);
	  transform:rotate(-6deg);
	}
	80% {  
	  -webkit-transform:rotate(6deg);
	  transform:rotate(6deg);
	}
	85% {  
	  -webkit-transform:rotate(-6deg);
	  transform:rotate(-6deg);
	}
	90% {  
	  -webkit-transform:rotate(6deg);
	  transform:rotate(6deg);
	}
	95% {  
	  -webkit-transform:rotate(-6deg);
	  transform:rotate(-6deg);
	}
	100% {  
	  -webkit-transform:rotate(0deg);
	  transform:rotate(0deg);
	}
}
.title-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:80%;
  margin:30px auto 10px
}
.type-box{
  text-align: center;width:130px;padding:20px 0;cursor: pointer;
}
.type-box:hover{
  box-shadow: rgba(31, 149, 220, 0.2) 0px 2px 8px 0px;
}
</style>
