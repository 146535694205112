<template>
  <div class="home">
    <swiper :options="swiperOption" class="swiper"  v-if="imgList.length>0" ref="mySwiper">
        <swiper-slide v-for="(item,index) of imgList" :key="index" class="swiper-slide">
          <img :src="item.indexImg" alt="">
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

  </div>
</template>

<script>
// @ is an alias to /src
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "Home",
  components:{swiper,swiperSlide},
  props: {
    imgList:Array
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay:3000,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false
        // },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  mounted() {

  },
};
</script>

<style scoped>
.swiper{
    height: calc(100vh - 80px);
  width: 100%;
}
img{
  object-fit: fill!important;
}
.swiper-slide {
  width: 100%;
  height: 100%;
  /* background-color: #42b983;
  text-align: center; */
  /* line-height: 500px; */
}
.swiper-slide img{
    width: 100%;
    height: 100%;
}

</style>
